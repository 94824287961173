import React, { useState, useEffect, lazy, Suspense } from "react";
import { ComingSoonMobileVersion } from "./components/ComingSoonMobileVersion";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import "./App.css";
import SpinnerLogo from "./assets/SpinnerLogo";
import { HelmetProvider } from "react-helmet-async";

const DashboardLayout = lazy(() => import("./components/DashboardLayout"));
const HomeScreen = lazy(() => import("./pages/NewHomeScreen"));
const TokensScreen = lazy(() => import("./pages/TokensScreen"));
const NFTsScreen = lazy(() => import("./pages/NFTsScreen"));
const TxnScreen = lazy(() => import("./pages/TxnScreen"));
const WalletNotFound = lazy(() => import("./pages/WalletNotFound"));

// Suppress logs in non-development environments
if (process.env.environment !== "development") {
  console.log = () => {};
  console.warn = () => {};
  console.error = () => {};
}

function App() {
  const [isMobile, setIsMobile] = useState(false);
  const [theme, setTheme] = useState(
    () => localStorage.getItem("theme") || "light"
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust threshold if needed
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme") || "light";
    if (savedTheme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => setLoading(false), 200);
    return () => clearTimeout(timer);
  }, []);
  if (loading) {
    return (
      <div
        className={`flex justify-center items-center ${
          theme === "dark" ? "bg-[#101113]" : "bg-white"
        } h-screen`}
      >
        <SpinnerLogo />
      </div>
    );
  }

  if (isMobile) {
    return <ComingSoonMobileVersion />;
  }

  return (
    <HelmetProvider>
      <Router>
        <Suspense
          fallback={
            <div className="flex bg-white dark:bg-[#101113] justify-center items-center h-screen">
              <SpinnerLogo />
            </div>
          }
        >
          <Routes>
            {/* Home Route */}
            <Route path="/" element={<HomeScreen />} />

            {/* Wallet Routes */}
            <Route path="/:walletId">
              {/* Redirect index to tokens */}
              <Route index element={<Navigate to="tokens" replace />} />

              {/* Tokens Screen */}
              <Route
                path="tokens"
                element={
                  <DashboardLayout>
                    <TokensScreen />
                  </DashboardLayout>
                }
              />

              {/* NFTs Screen */}
              <Route
                path="nfts"
                element={
                  <DashboardLayout>
                    <NFTsScreen />
                  </DashboardLayout>
                }
              />

              {/* Transactions Screen */}
              <Route
                path="transactions"
                element={
                  <DashboardLayout>
                    <TxnScreen />
                  </DashboardLayout>
                }
              />

              {/* Wallet Not Found Screen */}
              <Route
                path="notFound"
                element={
                  <DashboardLayout>
                    <WalletNotFound />
                  </DashboardLayout>
                }
              />

              {/* Catch-all for unmatched routes under /:walletId */}
              <Route
                path="*"
                element={
                  <DashboardLayout>
                    <WalletNotFound />
                  </DashboardLayout>
                }
              />
            </Route>

            {/* Global Catch-all for other unmatched routes */}
            <Route
              path="*"
              element={
                <DashboardLayout>
                  <WalletNotFound />
                </DashboardLayout>
              }
            />
          </Routes>
        </Suspense>
      </Router>
    </HelmetProvider>
  );
}

export default App;
